import { ReactNode } from "react";

import { Tag, TagLabel, TagProps } from "@chakra-ui/react";

interface Props {
  label: string;
  icon: ReactNode;
  contentColor?: string;
}
export default function IconChip({
  label,
  icon,
  contentColor = "brand.dark",
  ...rest
}: Props & TagProps) {
  return (
    <Tag
      variant="subtle"
      backgroundColor="brand.light"
      gap={0.5}
      borderRadius="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={2}
      py={1}
      size={"md"}
      {...rest}
    >
      {icon}
      <TagLabel fontWeight="semibold" color={contentColor}>
        {label}
      </TagLabel>
    </Tag>
  );
}
