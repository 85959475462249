import React, { useState } from "react";
import { Mail } from "react-feather";

import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Circle,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import { requestExpertReview, sendEventButtonClick } from "../api";
import { ButtonClickEventNames } from "../types";

import ExpertReviewImage from "../images/expert_assist_expert_review.png";

type Props = {
  screenId: string;
  userEmail: string;
};

export const ExpertReviewModalBody: React.FC<Props> = ({
  screenId,
  userEmail,
}) => {
  const [requestedExpertReview, setRequestedExpertReview] = useState(false);

  const onButtonClick = async () => {
    setRequestedExpertReview(true);
    sendEventButtonClick({
      buttonName: ButtonClickEventNames.REQUEST_EXPERT_REVIEW,
      screenId: screenId,
    });

    await requestExpertReview();
  };

  const getNextBusinessDay = () => {
    const date = new Date();
    const dayOfWeek = date.getDay();

    // Add days based on current day of week
    // Friday (5) -> +3 days to Monday
    // Saturday (6) -> +2 days to Monday
    // Sunday (0) -> +1 day to Monday
    // Otherwise -> +1 day
    const daysToAdd =
      dayOfWeek === 5 ? 3 : dayOfWeek === 6 ? 2 : dayOfWeek === 0 ? 1 : 1;

    date.setDate(date.getDate() + daysToAdd);

    // Format as MM/DD with padding
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${month}/${day}`;
  };

  const PreEnrollmentContent = () => (
    <Flex flexDir="column" justify="center" align="center" gap={5} width="full">
      <Heading size="lg" textAlign="center">
        Get an Expert Review
      </Heading>
      <Text size="md">
        Expert Reviews include recommended updates to make this year, plus tips
        for next year too.
        <br></br>
        <br></br>
        <b>Your email:</b> {userEmail}
      </Text>
      <Button
        onClick={onButtonClick}
        width="full"
        variant="outline"
        mb="6"
        leftIcon={<Mail />}
      >
        Get an Expert Review
      </Button>
    </Flex>
  );

  const PostEnrollmentContent = () => (
    <Flex flexDir="column" justify="center" align="center" gap={5} width="full">
      <Heading size="lg" textAlign="center">
        Your Expert Review<br></br>is Coming Soon
      </Heading>
      <Text size="md">
        <b>Please note:</b> tax returns can’t be updated once submitted, so hold
        off on submitting until you check your Expert Review.
        <br></br>
        <br></br>
        <b>Your email:</b> {userEmail}
      </Text>
      <Center>
        <HStack spacing={2}>
          <Circle size={6} bg="success.medium" color="white">
            <CheckIcon boxSize={3} />
          </Circle>
          <Text color="success.medium">
            Your review should arrive by {getNextBusinessDay()}
          </Text>
        </HStack>
      </Center>
    </Flex>
  );

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      width={{ base: "full", md: "472px" }}
      py={{ base: 6, md: 0 }}
      px={{ base: 3, md: 0 }}
      gap={5}
    >
      <Image src={ExpertReviewImage} maxH="110px" width="auto" />
      {requestedExpertReview ? (
        <PostEnrollmentContent />
      ) : (
        <PreEnrollmentContent />
      )}
    </Flex>
  );
};

export default ExpertReviewModalBody;
