import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import NumberFormat from "react-number-format";

import { Box, FormControl, Input } from "@chakra-ui/react";

import { ReactHookFormError, StringField } from "../types";

import { ErrorMessages, isInvalid, resolveVariant } from "../helpers/Errors";
import InputFieldLabel from "./InputFieldLabel";
import StyledInput from "./StyledInput";

export interface StringFieldProps {
  field: StringField;
  register: UseFormRegister<FieldValues>;
  error: ReactHookFormError;
  control: Control;
  isLoading: boolean;
}
const StringFieldInput: React.FC<StringFieldProps> = ({
  field,
  register,
  error,
  control,
  isLoading,
}) => {
  let patternProps = {};

  let re: RegExp;
  if (field.inputRegex) {
    re = new RegExp(field.inputRegex);
  }

  // If the string is numeric, then only allow digits
  // We do not switch to type="number" because that ignores
  // the maxLength value
  // see: https://stackoverflow.com/questions/18510845/maxlength-ignored-for-input-type-number-in-chrome
  if (field.numeric) {
    patternProps = { pattern: "\\d*" };
    if (!field.inputRegex) {
      re = new RegExp("^\\d*$");
    }
  }
  return (
    <Box>
      <FormControl isInvalid={isInvalid(error)} isDisabled={isLoading}>
        <InputFieldLabel field={field} />
        {/* If the field is numeric then we use the NumberFormat component to show the number keyboard on mobile
        We cannot use an Input with type="number" because that ignores the maxLength value */}
        {field.numeric ? (
          <Controller
            render={({ field: { onChange, value, onBlur } }) => (
              <NumberFormat
                allowLeadingZeros={true}
                variant={resolveVariant(error)}
                allowNegative={false}
                onValueChange={(v) => onChange(v.value)}
                value={value}
                placeholder={field.placeholder}
                customInput={StyledInput}
                onBlur={onBlur}
                name={field.id}
                maxLength={field.maxLength}
              />
            )}
            name={field.id}
            control={control}
            defaultValue={field.answer}
          />
        ) : (
          <Input
            variant={resolveVariant(error)}
            placeholder={field.placeholder}
            maxLength={field.maxLength}
            isDisabled={field.disabled}
            onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
              // Check to see if the input character matches the regex
              // provided by the backend.
              if (re && e.data && !re.test(e.data)) {
                e.preventDefault();
              }
            }}
            {...patternProps}
            {...register(field.id)}
          />
        )}
        <ErrorMessages error={error} />
      </FormControl>
    </Box>
  );
};

export default StringFieldInput;
