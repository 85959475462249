export let globalFeatureFlags: FeatureFlags | null = null;
export function setGlobalFeatureFlags(flags: FeatureFlags | null) {
  globalFeatureFlags = flags;
}

// If we read feature flags, we need to add them as known keys here to pass typechecking.
export type FeatureFlags = {
  offloadFrontendTrafficToWebhooks: boolean;
  useZendeskSandbox: boolean;
  enableFullview: boolean;
  useSlowBackendForW2Uploads: boolean;
  showInternalOverflowMenu: boolean;
  enableUxrRecruitmentChip: {
    enabled: boolean;
    content: {
      buttonText: string;
      chipLabel: string;
      modalDescription: string;
      modalTitle: string;
      buttonLink: string;
    };
  };
};
