import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Fade,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { getUploadW2Status } from "../../api";
import { Resource, Screen, UploadStatus, UploadType } from "../../types";

import { MIN_CARD_INNER_HEIGHT } from "../../../utils/constants";
import { delay } from "../../../utils/general";
import AnimatedProgressBar from "../../icons/AnimatedProgressBar";
import AnimatedTypewriter from "../../icons/AnimatedTypewriter";

interface Props {
  // This screen prop is the W2::UploadWaitScreen, which contains this W2WaitBlock.
  // Note that while this screen can feel a bit like an interlude screen
  // (just waiting....), it is implemented as a Navigation screen. This is
  // because Interlude screens have a much more specific static, defined
  // behavior "wait-X then navigate no matter what" behavior.
  // The W-2 upload wait screen is much more similar to our Drake
  // FallbackLoadingScreen
  // TODO(marcia): Consider unifying these two polling screens.
  screen: Screen;
  onSubmit: (screenId: string) => void;

  // We call setScreen on the provided screen payload when we
  // successfully process the W-2 and can show the prefilled FirstW2Screen
  // TODO(marcia): Update to be a required prop and remove ? and checks
  setScreen?: (screen: Screen) => void;

  timeoutMilliseconds: number;
  uploadType: UploadType;
  desiredTaxYear?: number;
}

const TIMEOUT_DURATION = 5000;

function PrefillWaitBlock({
  screen,
  setScreen,
  timeoutMilliseconds,
  uploadType,
  desiredTaxYear,
}: Props) {
  const [label, setLabel] = useState("Uploading...");
  const [filename, setFilename] = useState<null | string>(null);
  const { onClose: onCloseModal } = useDisclosure();
  const toast = useToast();
  const timedOut = useRef<boolean>(false);

  async function checkW2UploadStatus() {
    // Check the status of our W-2 upload
    const results = await getUploadW2Status({
      resourceUuid: (screen.resource as Resource)?.uuid,
      timedOut: timedOut?.current || false,
      uploadType: uploadType || UploadType.W2,
    });

    if (results) {
      if (results.screen && setScreen) {
        setScreen(results.screen);
        if (uploadType === UploadType.IRS1040) {
          if (results.status === UploadStatus.SUCCEEDED) {
            toast({
              title: `${results.originalFilename} was uploaded`,
              status: "success",
              variant: "success",
              position: "top",
              duration: TIMEOUT_DURATION,
              isClosable: true,
            });
          } else {
            // TODO(marcia): Remove 2023 fallback once both BE + FE have been deployed.
            toast({
              title: `Is this your ${desiredTaxYear ? desiredTaxYear : "2023"} Form 1040? If the error persists, click Skip. We’ll help you file your tax return without it.`,
              status: "error",
              variant: "error",
              position: "top",
              // Include a null duration for the error toast. We want this toast
              // to stay visible until the taxpayer explicitly dismisses it.
              duration: null,
              isClosable: true,
            });
          }
        }
      } else if (results.originalFilename) {
        setFilename(results.originalFilename);
      }
    }
  }

  const updateTimedOut = async () => {
    // If we've reached our max timeout, we'll let the BE know
    // that we've timed out. It will then mark the upload as an error
    // and show the user the error modal.
    await delay(timeoutMilliseconds);

    if (timedOut) {
      timedOut.current = true;
    }
  };

  const delayUpdateText = async () => {
    await delay(6 * 1000);
    setLabel("Transcribing...");
  };

  useEffect(() => {
    // Start our timer for a max timeout
    updateTimedOut();

    // Update text artificially after 3 seconds to give a sense of progress
    delayUpdateText();

    // Poll every 8 seconds, since google can be slow
    // TODO(marcia): Re-visit cadence -- see o/w2-dashboard for average and max
    // processing times. Maybe we could wait an initial 5 seconds and then poll
    // at 3 seconds after. (The interval was initially set arbitrarily to 3 sec,
    // but bumped up to 5 sec after noticing 404 errors on the W-2. See
    // https://github.com/column-tax/column/pull/1494)
    const interval = setInterval(() => {
      checkW2UploadStatus();
    }, 8 * 1000);
    // Clean up our interval afterwards
    return () => clearInterval(interval);
  }, []);

  return (
    <Flex
      direction="column"
      justifyContent="space-around"
      minHeight={MIN_CARD_INNER_HEIGHT}
    >
      <Modal isOpen={true} closeOnOverlayClick={false} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent margin="0">
          <ModalBody>
            <Flex direction="column" gridGap="16px">
              <Heading size="h4">{label}</Heading>
              <AnimatedProgressBar />
              {/* Assign a height to this box so that nothing shifts when
        the filename fades in (which we get from the BE) */}
              <Box height="var(--chakra-lineHeights-md)">
                <Fade in={!!filename}>
                  <Text fontWeight="var(--chakra-fontWeights-semibold)">
                    {filename}
                  </Text>
                </Fade>
              </Box>
            </Flex>
            <Box mt={4}>
              <Flex justifyContent="center">
                <AnimatedTypewriter />
              </Flex>

              <Box textAlign="center" my="8">
                <Text color="text.secondary" fontSize="md">
                  This process can take up to 30 seconds.
                  <br />
                  Please stay on this screen until it's done.
                </Text>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default PrefillWaitBlock;
