import { useContext } from "react";
import { MessageCircle } from "react-feather";

import {
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToken,
} from "@chakra-ui/react";

import { FeatureFlagContext } from "../../App";
import UXRRecruitmentIllustration from "../images/uxr_recruitment_illustration.png";
import IconChip from "./Chip";

export default function UXRRecruitmentChip() {
  const [brandMedium] = useToken("colors", ["brand.medium"]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const context = useContext(FeatureFlagContext);
  const featureFlags = context?.featureFlags;
  const uxrFlag = featureFlags?.enableUxrRecruitmentChip;

  const content = uxrFlag?.content;

  const onButtonClick = () => {
    if (content?.buttonLink) {
      window.open(content.buttonLink, "_blank");
    }
  };

  if (!content) return null;

  return (
    <>
      <IconChip
        label={content?.chipLabel}
        icon={<MessageCircle color={brandMedium} width={20} height={20} />}
        contentColor="brand.medium"
        gap={1}
        cursor="pointer"
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width={{ base: "100%", md: "480px" }}>
          <ModalCloseButton size="md" />
          <ModalBody
            px={{ base: 5, md: 0 }}
            pt={{ base: "68px", md: 0 }}
            pb={{ base: 12, md: 0 }}
            width="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Flex
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              gap={8}
              width={{ base: "100%", md: "335px" }}
            >
              <Image
                src={UXRRecruitmentIllustration}
                alt="UX Research Opportunity"
                width={100}
                height={100}
              />
              <Flex flexDir="column" gap={5}>
                <Heading size="lg" textAlign="center">
                  {content?.modalTitle}
                </Heading>
                <Text size="md" textAlign={{ base: "center", md: "left" }}>
                  {content?.modalDescription}
                </Text>
              </Flex>
              <Button variant="primary" width="full" onClick={onButtonClick}>
                {content?.buttonText}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
